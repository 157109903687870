import React from 'react'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import '../../../components/component-style/blog.scss'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

const ihack_post = ({ data }) => {
    return (
        <Layout>
            <SEO title="iHack G4" />
            <div className="blog-post-container">
                <div className="blog-post">
                    <h1>iMac G4 Retrofit</h1>
                    <h2>2020-04-02</h2>
                    <div className="blog-post-content">
                        <div className="imgFull">
                            <GatsbyImage
                                image={data.ihackAngles.childImageSharp.gatsbyImageData}
                                alt="NAS overview" />
                        </div>
                        <p>
                            <div className="imgLeft">
                                <GatsbyImage image={data.ihack.childImageSharp.gatsbyImageData} alt="ihack" />
                                <p>This guy bops</p>
                            </div>
                            <span>
                                I'd like to start by shouting out{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="http://www.dremeljunkie.com/"
                                >
                                    Dremel Junkie
                                </a>
                                , who has over the years posted a ton of super
                                helpful information and documentation on iMac
                                mods. I wish I'd discovered his site earlier on
                                in the process, as it definitely would've saved
                                many a headache and improved my internal layout
                                on the machine. I also failed to take many
                                photos during the initial disassembly, and
                                Dremel Junkie was kind enough to let me rip a
                                few.
                            </span>
                        </p>
                        <p>
                            These sorts of projects are all over. From this{' '}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.reddit.com/r/DIY/comments/4f35h6/"
                            >
                                Raspberry Pi Super Nintendo
                            </a>{' '}
                            to a{' '}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.reddit.com/r/DIY/comments/7wf5cb/"
                            >
                                Modern System in a 386 case
                            </a>{' '}
                            to a{' '}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.reddit.com/r/sleeperbattlestations/comments/f64ijl/"
                            >
                                Ryzen in a TRS80
                            </a>{' '}
                            to &mdash; perhaps my personal favorite &mdash; this{' '}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://imgur.com/gallery/bxUlq"
                            >
                                PC in an old Philco radio
                            </a>
                            , giving new life to an enclosure designed decades
                            ago has a certain magic to it. I stumbled upon the
                            chassis for this build while on Craigslist
                            scavenging for parts for{' '}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="/blog/2019-09-23-nas"
                            >
                                another build
                            </a>
                            , and at $30 I couldn't say no. Even if I just used
                            it as an external display or stuffed a Raspberry Pi
                            into it for a low powered linux machine, I could get
                            some use out of it (in retrospect this is a bad way
                            to justify purchases).
                        </p>
                        <p>
                            Although its internals make it ancient in computer
                            terms, the design is striking: unlike anything Apple
                            had done before, with its flat panel screen and
                            hemispherical base, and visually revolutionary in a
                            way that few of their devices have been since. Just
                            look how cute it is{' '}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.youtube.com/watch?v=b5P3QDm61go"
                            >
                                in the ad
                            </a>
                            . Beautiful exterior and garbage components: perfect
                            for a retrofit.
                        </p>
                        <h3>Initial Impressions</h3>
                        <p>
                            The device is a circa 2002{' '}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://everymac.com/systems/apple/imac/specs/imac_800_17_fp.html"
                            >
                                iMac G4 800 17&quot;
                            </a>{' '}
                            with a blazing fast 800MHz PowerPC processor, 256MB
                            SDRAM, an 80GB HDD, and an included Apple Pro Mouse
                            and Keyboard set. I knew when I got it that the
                            display was nonfunctional, but I didn&#39;t mind
                            having to upgrade the 1440x900 screen to a 1920x1200
                            one. My plan was to do a pretty simple swap and
                            reuse both the backlight inverter in the display
                            assembly as well as the{' '}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://hackaday.io/project/4177-lvds-laptop-display-interfacing"
                            >
                                LVDS
                            </a>{' '}
                            wiring already running through the goose neck
                            (there&#39;s some debate on what the pivot arm
                            should be called, so I hope{' '}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.youtube.com/watch?v=lHxmgZr9eLA"
                            >
                                this video of geese eating apples
                            </a>{' '}
                            will convince you). 17&quot; replacement LCDs and
                            their driver boards are easy to find online since
                            it&#39;s a common size for larger laptops.
                        </p>
                        <p>
                            As a tribute to a respectable piece of hardware
                            &mdash; and to see what would happen &mdash; I tried
                            powering on the machine. The sounds of the cooling
                            fan and hard disk spinning greeted me, but the blank
                            screen was a little concerning. I wasn&#39;t
                            expecting to see an output image, but the lack of a
                            backlight gave a little insight into the problems I
                            would later face.
                        </p>
                        <p>
                            <div className="imgLeft">
                                <GatsbyImage
                                    image={data.miniVga.childImageSharp.gatsbyImageData}
                                    alt="Round Motherboard" />
                                <p>All "mini VGA"</p>
                            </div>
                            <span>
                                The only display output apart from the built-in
                                screen is a{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://en.wikipedia.org/wiki/Mini-VGA"
                                >
                                    mini-VGA
                                </a>{' '}
                                port on the back. I had never used or even heard
                                of it, and from what I could tell, the name can
                                refer to a variety of different connectors used
                                by various manufacturers. For my purposes it
                                didn&#39;t seem worthwhile to spend extra time
                                and money on fixing a system I was about to gut,
                                so I said my farewells, powered it down for the
                                last time, and got out the screwdrivers.
                            </span>
                        </p>
                        <p>
                            The port array on the back seems reasonably
                            comprehensive at the time &mdash; other than the
                            display interface. It&#39;s got 3 USB 1.1 ports (2.0
                            had just come out and wasn&#39;t adopted to the iMac
                            until 2003), 2 Firewire 400 ports, an Apple Pro
                            Speakers mini-jack, a phone jack for the 56k modem,
                            a 100Mbps ethernet port, and a headphone jack.
                            Experts speculate that the headphone jack was
                            included simply so that when the 2003 model with
                            optional bluetooth was released AirPod users would
                            want to upgrade. However, there appears to have been
                            a lack of communication between development teams
                            since that module used Bluetooth 1.1 and the AirPods
                            require at least 4.0. Crazy.
                        </p>
                        <p>
                            <div className="imgCenter">
                                <GatsbyImage
                                    image={data.origIo.childImageSharp.gatsbyImageData}
                                    alt="Original rear IO" />
                            </div>
                        </p>

                        <h3 id="the-teardown">The teardown</h3>
                        <p>
                            This is just an overview of the process; if
                            you&#39;re trying to recreate the mod, I highly
                            recommend Dremel Junkie&#39;s website above.{' '}
                        </p>
                        <p>
                            I started by taking off the front of the display
                            assembly, which houses the LCD panel and the
                            backlight inverter, just to see what I was working
                            with. Immediate disappointment. Not only was I
                            getting no readout from the inverter on my
                            multimeter, but I also found that the LCD was using
                            a{' '}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://en.wikipedia.org/wiki/Transition-minimized_differential_signaling"
                            >
                                TDMS
                            </a>{' '}
                            connection. This is a standard that powers both DVI
                            and HDMI but is pretty uncommon as an internal
                            display driver compared to LVDS.
                        </p>
                        <p>
                            On to the dome. Under the access plate (which
                            incidentally is the only spot not using torx
                            screws), we find the user-upgradeable RAM and
                            airport card. I find it interesting that the
                            internal RAM slot is a full length 135mm DIMM slot,
                            but the one accessible by the user is a ~70mm SODIMM
                            slot. I'm not sure that I've encountered mixed RAM
                            form factors before, though I would imagine that
                            giving the user access to only one slot for
                            expansion was intended to 'simplify' the upgrade
                            path.
                        </p>
                        <p>
                            <div className="imgLeft">
                                <GatsbyImage image={data.mobo.childImageSharp.gatsbyImageData} alt="Round Motherboard" />
                                <p>Image courtesy Dremel Junkie</p>
                            </div>
                            <span>
                                Digging further, the base of the dome can be
                                removed, revealing the guts of the machine,
                                which includes perhaps the only circular
                                motherboard in a consumer desktop pc. I did a
                                little searching but couldn&#39;t find any other
                                examples. Theoretically you could upgrade up to
                                1GB of RAM in this guy since 133MHz PCI RAM
                                could be found in up to 512MB modules, but a
                                curious eBay search could only find PCI133 RAM
                                as part of a bundle with an old server
                                motherboard (and no SODIMMs).
                            </span>
                        </p>
                        <p>
                            The bulk of the space in the dome itself is taken up
                            by the storage block, which is an aluminum cage with
                            a disk drive mounted to one side and the 3.5&quot;
                            HDD mounted to the other. Though the tongue-out CD
                            drive is cute, I would need to replace it since the
                            original has an IDE interface, plus it takes up a
                            ton of space, so I opted to scrap my dreams of
                            circular storage mediums. I saved the hinging
                            mechanism for the drive door in case I change my
                            mind some day and want to rebuild it, but it&#39;s
                            entirely aesthetics since I usually don&#39;t have
                            much use for a disk drive.
                        </p>
                        <p>
                            Also contained in the dome is the power supply
                            mounted on the side, an 80mm case fan, the Apple
                            chime speaker, and a bunch of wires coming from the
                            goose neck.
                        </p>
                        <p>
                            At this point I realized how much space I actually
                            had to work with, and that just sticking in a Pi and
                            display controller might be underkill.
                        </p>
                        <h3 id="introducing-the-intel-nuc-">
                            Introducing the Intel NUC!
                        </h3>
                        <p>
                            This little guy packs a punch at 115x111x36mm in its
                            enclosure. It&#39;s basically laptop brains in a box
                            with many features typically only available on
                            desktop boards like extra USB/panel headers, serial
                            headers, and no touchpad.
                        </p>
                        <p>
                            <div className="imgLeft">
                                <GatsbyImage image={data.nuc.childImageSharp.gatsbyImageData} alt="rear IO" />
                                <p>NUC with display control board and cage</p>
                            </div>
                            <span>
                                The model I used is a Dawson Canyon NUC (
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://ark.intel.com/content/www/us/en/ark/products/130392/intel-nuc-kit-nuc7i7dnke.html"
                                >
                                    7i7DNKE
                                </a>
                                ) with an 8th gen i7-8650U that I found used on
                                eBay. I stuck 16GB of DDR4 ram and a 240GB M.2
                                drive in it, and it seems pretty happy. Since I
                                wanted to load macOS on the drive, the included
                                Intel M.2 WiFi card wouldn&#39;t work. Rather
                                than spending $50 on a Broadcom chip, I found a
                                USB3.1 Mac-compatible WiFi dongle and plugged
                                her in. Unfortunately this means that I
                                don&#39;t have the option to run antennas to the
                                outside of the faraday cage, but I found I was
                                getting pretty decent signal in my tests with
                                the adapter positioned near a cutout.
                            </span>
                        </p>
                        <p>
                            The idea for using a NUC came from{' '}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.youtube.com/watch?v=J-wHQEiDKr4"
                            >
                                this Quinn Nelson video
                            </a>
                            , and the majority of the hackintosh process for
                            them is documented{' '}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.tonymacx86.com/threads/guide-intel-nuc7-nuc8-using-clover-uefi-nuc7i7bxx-nuc8i7bxx-etc.261711/"
                            >
                                on TonyMac x86
                            </a>{' '}
                            (though I still had to do a lot of troubleshooting
                            to get it to actually work).
                        </p>
                        <h3 id="the-build">The build</h3>
                        <p>
                            <div className="imgRight">
                                <GatsbyImage image={data.neckScrew.childImageSharp.gatsbyImageData} alt="Monitor Arm" />
                                <p>'tis but a scratch</p>
                            </div>
                            <span>
                                I started with the display. I needed to replace
                                the wires in the gooseneck, so I first tried to
                                use the old ones to pull the new 30-pin LVDS and
                                inverter power wires through &mdash; to no
                                avail. The wires are stuck in place internally
                                (enabling the neck to move freely), so I had to
                                crack open the neck to avoid running them
                                externally. The screws holding it together use a
                                proprietary 3-point bit, so naturally I just
                                Dremelled out a channel that I could stick a
                                flathead driver into and it opened up like a
                                charm. After carefully wrapping and routing the
                                cabling, I attempted to put it back together. It
                                did not close like a charm. Inside the goose
                                neck is a massive spring that keeps the arm in
                                position with the weight of the monitor on it,
                                and that thing is hefty.
                            </span>
                            <div className="imgLeft">
                                <GatsbyImage image={data.neck.childImageSharp.gatsbyImageData} alt="Monitor Arm" />
                                <p>Image courtesy Dremel Junkie</p>
                            </div>
                            <span>
                                I fought with the neck assembly for hours over
                                multiple days. After almost giving up on the
                                whole project a few times because of it, I was
                                finally able to shimmy it into place using a
                                steel allen wrench as a prybar (I actually had
                                to do this twice because the first time I forgot
                                the inverter wires). To anyone attempting: do
                                not take apart the goose neck unless you
                                absolutely must, and double check your work
                                before closing it. Opening the neck appears to
                                anger the goose.
                            </span>
                        </p>
                        <p>
                            <div className="imgRight">
                                <GatsbyImage image={data.inverterMount.childImageSharp.gatsbyImageData} alt="inverter" />
                                <p>At least you can't see it from the front</p>
                            </div>
                            <span>
                                At this point I ran into a few problems. First,
                                the panel I was trying to swap in had slightly
                                bigger bezels than the old one, and while I
                                could pretty easily modify the mounting hardware
                                to accommodate it, there was no room left for
                                the inverter. Additionally, there appeared to be
                                a short in the inverter wires somewhere in the
                                gooseneck because, while they had continuity,
                                they couldn&#39;t carry a signal. Luckily the
                                LVDS wires were unaffected by this. Rather than
                                disassembling the neck for a third time, I
                                conceded to stealthily mount the inverter on the
                                back of the display assembly and to have just
                                the four wires needed for inverter power running
                                externally. At some point I&#39;d like to go
                                back and clean this up, but for now it&#39;s
                                functional and not too unsightly.
                            </span>
                        </p>
                        <p>
                            <div className="imgLeft">
                                <GatsbyImage
                                    image={data.fanControl.childImageSharp.gatsbyImageData}
                                    alt="fan controller" />
                                <p>Perhaps overkill but it works</p>
                            </div>
                            <span>
                                After spending so much time fighting with the
                                display connections, I was pretty excited to
                                move back to the dome. I started by swapping out
                                the existing case fan for a quiet 80mm{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://noctua.at/en/nf-b9-redux-1600-pwm"
                                >
                                    Noctua NF-B9
                                </a>{' '}
                                with PWM control. Unfortunately, one thing the
                                NUC is missing is extra fan headers, so I rigged
                                up a little controller that adjusts the fan
                                speed based on the internal case temperature
                                using an{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.digikey.com/product-detail/en/analog-devices-inc/TMP36GT9Z/TMP36GT9Z-ND/"
                                >
                                    Analog Devices temperature sensor
                                </a>
                                . The controller is based around an Arduino
                                micro (overkill) and just linearly scales the
                                fan speed from 'off' to 'max' based on a
                                temperature range. Since the cpu has its own
                                cooler, this should be plenty to keep the system
                                cool. Luckily the original fan was a standard
                                80x25mm case fan, so the new one just used the
                                existing mounting hardware.
                            </span>
                        </p>
                        <p>
                            <div className="imgRight">
                                <GatsbyImage image={data.rearIo.childImageSharp.gatsbyImageData} alt="rear IO" />
                                <p>HDMI port might still need some work</p>
                            </div>
                            <span>
                                Before working on shoving the guts in (but after
                                checking that said guts would actually fit), I
                                turned my attention to the rear I/O. As
                                mentioned previously, the main I/O included 3
                                USB1.1, 2 Firewire 400, and a 100Mbps ethernet
                                port, which just won&#39;t do for a modern
                                system! The USB ports could have been reused for
                                USB 2, but I ripped them out anyway and got some
                                USB 3 extension cables to put in their place and
                                run to the NUC, since it already has all the
                                main ports I want accessible. I had to trim the
                                ends a bit with the Dremel so that they fit next
                                to each other in the existing holes, but I think
                                they turned out about as well as I could have
                                hoped given what I&#39;m doing to the poor
                                thing. They&#39;re all stuck in place with some
                                high-tech thermoplastic adhesive.
                            </span>
                        </p>
                        <p>
                            I wasn&#39;t using anything on the original
                            motherboard besides the rear I/O, and I hadn&#39;t
                            used the Dremel in several minutes, so I got it back
                            out and cut the whole thing in half.
                        </p>
                        <p>
                            <div className="imgCenter">
                                <GatsbyImage
                                    image={data.halfMobo.childImageSharp.gatsbyImageData}
                                    alt="Motherboard carcass" />
                                <p>
                                    It took a whole team to make it round, but 5
                                    minutes with a Dremel to undo it
                                </p>
                            </div>
                            Much better.
                        </p>
                        <p>
                            The ethernet port got a similar treatment to USB. I
                            fitted a cat-6 RJ45 extender that ran to the 1Gbps
                            port on the NUC. The last main port I cared about
                            was HDMI. The extender end wouldn&#39;t fit where
                            the mini-VGA port was, but after cutting out the
                            plastic column between the dual Firewire ports it
                            fit there just fine. My last alteration was adding
                            some wires to the momentary switch behind the
                            original power button so that it could still be
                            used. For now I left the mini-VGA, DSL, Pro Speaker,
                            and headphone jacks alone since I didn&#39;t have
                            anything better to put there. I&#39;d like to use
                            the original headphone jack, but at the moment my
                            macOS install isn&#39;t working with HDMI audio
                            output, so I&#39;m going to try to fix the software
                            before I do the hardware. I do have audio through{' '}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.bhphotovideo.com/c/product/1123982-REG/"
                            >
                                this USB audio adapter
                            </a>
                            , so if all else fails I might just put that inside
                            and wire it to the built-in headphone jack.
                        </p>
                        <p>
                            <div className="imgLeft">
                                <GatsbyImage image={data.coreAssembly.childImageSharp.gatsbyImageData} alt="rear IO" />
                                <p>
                                    The "Guts": Display board, power supplies,
                                    and NUC mounted to the cage
                                </p>
                            </div>
                            <span>
                                On to the guts! I salvaged the drive carrier to
                                mount everything on since it already had holes
                                that lined up with the screw holes of the
                                chassis. It&#39;s lightweight aluminum, so I was
                                able to cut off any bits that blocked my
                                hardware and then drill holes for the standoffs
                                that the display and fan controllers would mount
                                to. I left the NUC in its case because it was
                                the easiest thing to do at the time, but it
                                would've been more efficient for both space and
                                cooling to remove the case and mount it like the
                                other boards. Besides the NUC, fan controller,
                                and display board, the cage also holds power
                                supplies: an 18v brick for the NUC and a 12v
                                board for the display, which also powers the
                                fan. The fan controller recieves 5v from a
                                regulator built into the display control board.
                            </span>
                        </p>
                        <p>
                            The final touch before shoving everything in was to
                            add a monitor power button and light to the front.
                            Since at the moment I&#39;m not using the disk drive
                            door, I sealed it to the surrounding plastic, added
                            an indicator LED behind it to glow through, and cut
                            a small hole for a tact switch. These got wired to
                            the display control board. I thought about doing
                            this for all of the OSD buttons that the control
                            board exposes, but I don&#39;t see myself messing
                            with the OSD controls enough to justify further
                            ruining the clean finish on the front.
                        </p>
                        <p>
                            Time to put it all together. The original power
                            button got wired to the fan header before I closed
                            up the NUC, so I left some slack on it so that I
                            could still work on the two halves separately while
                            putting in the main block. I left a lot less slack
                            on the LVDS wires coming from the goose neck so that
                            there wouldn&#39;t be anything to get caught in the
                            fan, so I plugged those in as I was lowering the
                            block into the dome. After that, I just needed to
                            plug in all of my port extenders, wire the two power
                            supplies into the original AC plug, and cable manage
                            enough to safely close the bottom.
                        </p>
                        <h3 id="software">Software</h3>
                        <p>
                            This was my first venture into Hackintoshes, but
                            between the TonyMac guide linked above and others
                            (including{' '}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://hackintosher.com/builds/mac-mini-killer-intel-broadwell-nuc/"
                            >
                                this good one from Hackintosher
                            </a>
                            ) I was able to get it up and running in a couple of
                            hours with most things working. For simplicity&#39;s
                            sake I got most of the kinks worked out before
                            actually sticking the NUC into the dome.
                        </p>
                        <p>
                            <div className="imgRight">
                                <GatsbyImage
                                    image={data.osVersion.childImageSharp.gatsbyImageData}
                                    alt="OS version info for the upgraded G4" />
                                <p>
                                    Version info; the G4 is running the latest
                                    version of Mojave
                                </p>
                            </div>
                            <span>Working:</span>
                        </p>
                        <ul>
                            <li>USB Audio</li>
                            <li>USB/HDMI ports</li>
                            <li>Ethernet</li>
                            <li>WiFi</li>
                            <li>Sleep and Wake</li>
                            <li>App Store, iMessage</li>
                        </ul>
                        <p>Not Working:</p>
                        <ul>
                            <li>HDMI Audio</li>
                            <li>Bluetooth (no bluetooth adapter)</li>
                            <li>DSL 56k modem</li>
                        </ul>
                        <p>
                            I don&#39;t want to talk a lot about the benchmarks,
                            because this is first and foremost a project about
                            giving new life to a beautiful old machine.
                        </p>
                        <p>She does hold her own though.</p>
                        <p>
                            I compared it to a late-2018 Mac Mini with an
                            i7-8700B that was benchmarked on Geekbench. It&#39;s
                            worth noting that the Mac Mini compared only has 8GB
                            of ram, but it also has a 6-core desktop i7, so that
                            heavily influences the multi-core score.
                        </p>
                        <p>
                            <div className="imgCenter">
                                <GatsbyImage
                                    image={data.i7vsg4.childImageSharp.gatsbyImageData}
                                    alt="Mac Mini i7 vs iHack comparison" />
                            </div>
                            So, the G4 isn&#39;t going to be setting any
                            records, but I spent just under $600 on this whole
                            build, and the i7 Mac Mini I&#39;m comparing it to
                            retails for around $1500.
                        </p>
                        <p>
                            Perhaps a more fair comparison is against the i3 Mac
                            Mini, which retails for $799, only a few hundred
                            dollars more than this build. It has a 4-core desktop
                            i3 and 8GB of ram.
                        </p>
                        <p>
                            <div className="imgCenter">
                                <GatsbyImage
                                    image={data.i3vsg4.childImageSharp.gatsbyImageData}
                                    alt="Mac Mini i3 vs iHack comparison" />
                            </div>
                            Would it have been a lot easier and more
                            time-efficient to just buy a Mac Mini? Yes.
                        </p>
                        <p>
                            Would I be willing to pay an extra $200 for
                            something less powerful that doesn&#39;t look as
                            cool? No.
                        </p>
                        <p>
                            If you&#39;ve done/want to do a project like this or
                            if you have any questions or comments, please{' '}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="/contact"
                            >
                                reach out
                            </a>
                            !
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ihack_post

export const query = graphql`{
  rearIo: file(relativePath: {regex: "/ihack/rear_io.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  rearCenter: file(relativePath: {regex: "/ihack/rear_center.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  halfMobo: file(relativePath: {regex: "/ihack/half_mobo.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  internalLayout: file(relativePath: {regex: "/ihack/internal_layout.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  inverterMount: file(relativePath: {regex: "/ihack/inverter_mount.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  i3vsg4: file(relativePath: {regex: "/ihack/i3vsg4.png/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  i7vsg4: file(relativePath: {regex: "/ihack/i7vsg4.png/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  coreAssembly: file(relativePath: {regex: "/ihack/core_assembly.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  fanControl: file(relativePath: {regex: "/ihack/fan_control.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  nuc: file(relativePath: {regex: "/ihack/nuc.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  osVersion: file(relativePath: {regex: "/ihack/os_version.png/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  mobo: file(relativePath: {regex: "/ihack/mobo.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  neck: file(relativePath: {regex: "/ihack/monitor_arm.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  neckScrew: file(relativePath: {regex: "/ihack/neck_screw.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  miniVga: file(relativePath: {regex: "/ihack/mini_vga.png/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  origIo: file(relativePath: {regex: "/ihack/original_io.png/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  ihack: file(relativePath: {regex: "/ihack_header.png/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  ihackAngles: file(relativePath: {regex: "/ihack-angles.jpg/"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, layout: FULL_WIDTH)
    }
  }
}
`
